.product-page{

    font-size: 20px;
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 4%;
  
    border-radius: 36px;
}
 .product-page   h1{
        padding-bottom: 2%;
        font-size: 38px;
        background: -webkit-linear-gradient(#77b9cf, #0f5369 ,#071b52 );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }



@media screen and (max-width: 900px) {
 .product-page{
     margin-left: 5%;   
     margin-right: 5%;}
  .product-page  h1{
font-size: 22px;
}
}