.infoCompany-container{
    color:darkgray;
    
    justify-content: center;
    
    }
    .infoCompany-name{
    font-weight: bold;
    display: block;
    }
    .infoCompany-info{
     
        margin-bottom: 1%;
        border-bottom:solid lightgrey 1px ;
        margin-left: 35%;
        margin-right: 35%;
     
    }
    
    @media screen and (max-width: 900px) { 
        .infoCompany-info{
         margin-bottom: 1%;
        border-bottom:solid lightgrey 1px ;
       margin-left: 15%;
       margin-right: 15%;
        
       }
       .infoCompany-info{
           font-weight: 500;
       }}