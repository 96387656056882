.slide-component-container{
/*//linear-gradient(to left,rgba(7,27,82,1)0%,rgba(27,179,228)100%) ;*/
  background: linear-gradient(to left,#071b52,#1bb3e4);
}

.slide-container {
  width: 72%;
  margin: auto;
 
  
}

h3 {
  text-align: center; }

.each-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .slide-container{
width: 85%;
  }
   .slide-container img{
      height: 150px;
      width:100%;
    
    }
  
  .each-slide div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 150px;
  }
}
.slides{
	width:1200px;
	height:400px;
}


