.footer-component{
    color: darkgray;
    padding:1%;
    background: linear-gradient(to left,#071b52,#1bb3e4);
   font-size: 14px;
cursor: pointer;
z-index: 2;

}

@media screen and (max-width: 900px) {
 .footer-component{
padding: 1px;
 font-size: 10px;
 }
 
}
