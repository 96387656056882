.App {
  text-align: center;
}






.particles{
  position:fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;

}

