.carusel{
    color: lightgray;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;}

.carusel h3{
 background: -webkit-linear-gradient(#1bb3e4, #0f5369 ,#071b52 );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 5%;
}








@media screen and (max-width: 900px) {
    .carusel
      h3{
          font-size: 15px;
      }  
    
    .legend{
   height: 88px;
    overflow:scroll; 

    border-color: black;
        }
         
        .legend::-webkit-scrollbar {
           -webkit-appearance: none;  display: none;
           width: 0;
           height: 0;
           padding: 0; margin: 0;
           border-color: black;
           scrollbar-color: black;
          } 

}

