.header-component{
    text-align: center;
    background: linear-gradient(to left,#071b52,#1bb3e4);
    padding: 1%;
    color:darkgray;
  
}


.nav-bar{
/*@at-root: ridge;
border-top-style: ridge;
*/
padding: 1%;
margin-left: 20%;
margin-right: 20%;

padding-left: 8%;
padding-right: 8%;
align-content: center;
align-items: center;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap:auto;}


.nav-buttons{
    list-style: none;
padding: 4%;
/*background: linear-gradient(to left,rgba(7,27,82,1)0%,rgba(27,179,228)100%) ;*/
border-radius:20px;

outline: none;}
  
.nav-buttons:hover {
    background:rgb(15, 36, 94);
    background: linear-gradient(to left,#071b52,#1bb3e4);
    outline: none;
  }



.nav-link{
text-decoration: none;
color:darkgray ;
font-size: 20px;
font-weight: 700;
letter-spacing: 2px;

}
/*tc mw7 center mt4  bb bt white //*/


@media screen and (max-width: 900px) {
 
    .nav-bar{
        border: none;
margin: 0;
padding: 3%;

grid-gap:auto;

    }
.logo{
    width: 40vw;
}
.nav-buttons{
padding: 2%;
 border-radius:10px;
outline: none;
      
 
    
    }
    .nav-link{
        font-size: 12px;
        font-weight: 600;
    }
}
@media screen and (max-width: 600px) {
    .header-component h4{padding: 0 4%;}
}
@media screen and (max-width: 500px) {
    .logo{
        width: 60vw;
    }
}