.contact-container{
    justify-content: center;
    background-color: white;
    z-index: 2;
    color: darkgrey;}
   .contact-container h2{
        color: darkgrey;
        margin-top: 3%;
        margin-bottom:2%;
    }
    
    
    
.map-image{
    margin-top: 4%;
    margin-bottom: 4%;
  z-index: 2;

    
  }


  @media screen and (max-width: 900px) {
    .contact-container{
        font-size: 12px;}
       .contact-container h2{
            font-size: 15px;
            margin-top: 10%;
            margin-bottom: 5%;
        }
       .contact-container img{
            width: 85%;
        }
    
  }