.homepage-container{
    color:gray;
    text-align: center;}
   .homepage-container h1{
        margin-bottom: 2%;
    }



.homepage-text-container{
    text-align: justify;
    text-justify: inter-character;

    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 8%;
    font-weight: 400;}
   
  .homepage-text-container  p:last-child {
      color: gray;
      font-weight: bold;
      }
 


@media screen and (max-width: 900px) {
    .homepage-container
        h1{
            font-size: 22px;
            margin-bottom: 5%;
        }
    
    .homepage-text-container{
        font-size: 14px;
font-weight: 300;
z-index: 1;}

      .homepage-text-container  p{
            margin-top: 5%;
        }
       .homepage-text-container p:last-child {
            color: gray;
            font-weight: bold;
            }


}