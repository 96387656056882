.infoContact-container{
    color: darkgray;
    align-items: center;
    justify-content: center;
    
    }
    
        .infoContact-name{
      display: block;font-weight: bold;
    }
    .infoContact-info{
        margin-bottom: 1%;
        border-bottom:solid lightgrey 1px ;
        margin-left: 35%;
        margin-right: 35%;

    }

    
    @media screen and (max-width: 900px) {
        .infoContact-info{
             
            margin-bottom: 1%;
            border-bottom:solid lightgrey 1px ;
            margin-left: 15%;
            margin-right: 15%;
       }
       .infoContact-info{
           font-weight: 500;
       }
   } 